<template>
  <main>
    <div class="product-shadow py-5 px-6">
      <div class="flex items-center  justify-between w-full">
        <div class="flex items-center"></div>
        <div>
          <div class="relative w-full">
            <button
              v-click-outside="close"
              @click="active = !active"
              id="options-menu"
              aria-haspopup="true"
              aria-expanded="true"
              type="button"
              class="border smallest text-primaryBlue focus:outline-none border-primaryBlue py-2 px-4 rounded inline-flex w-32 justify-between capitalize items-center"
            >
              Add filter
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </button>
            <div
              v-show="active"
              @click="close"
              class="absolute white-gradient px-4 py-6 max-w-sm w-full bg-white mt-1 rounded-md"
            >
              <div class="flex w-full justify-between">
                <div class="w-full ml-8">
                  <p class="text-xs font-medium">By Date</p>
                  <div class="mt-2 flex items-center">
                    <input
                      type="radio"
                      v-model="time"
                      value="today"
                      name=""
                      id=""
                    />
                    <p class="text-xs ml-3 leading-7">Today</p>
                  </div>
                  <div class="mt-2 flex items-center">
                    <input
                      type="radio"
                      value="currentMonth"
                      v-model="time"
                      name=""
                      id=""
                    />
                    <p class="text-xs ml-3 leading-7">This Month</p>
                  </div>
                  <div class="mt-2 flex items-center">
                    <input
                      type="radio"
                      name=""
                      value="lastMonth"
                      v-model="time"
                      id=""
                    />
                    <p class="text-xs ml-3 leading-7">Last Month</p>
                  </div>
                  <div
                    @click.stop="toggle = !toggle"
                    class="mt-2 flex relative items-center"
                  >
                    <svg
                      class="w-3 h-3"
                      :class="{
                        'fill-current text-blue-500': time == 'custom',
                      }"
                      viewBox="0 0 15 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.975586"
                        y="2.43408"
                        width="12.7281"
                        height="12.2995"
                        rx="1.65333"
                        stroke="#253B95"
                        stroke-width="1.23999"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.168 1.06738V3.8006"
                        stroke="#253B95"
                        stroke-width="1.23999"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M4.51079 1.06738V3.8006"
                        stroke="#253B95"
                        stroke-width="1.23999"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M0.975586 6.53404H13.7037"
                        stroke="#253B95"
                        stroke-width="1.23999"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    <p
                      @click="open = !open"
                      class="text-xs ml-3 leading-7 text-primaryBlue cursor-pointer"
                    >
                      Select Date
                    </p>
                    <div class="relative">
                      <date-picker
                        ref="datepicker"
                        v-model="custom"
                        :inline="true"
                        v-if="open"
                        @change="handleChange"
                        format="YYYY-MM-DD"
                        class="absolute shadow-md -ml-5 mt-2"
                        range
                      ></date-picker>
                    </div>
                  </div>
                </div>
                <div class="border-l pl-8 w-full">
                  <p class="text-xs font-medium">By Status</p>
                  <div class="mt-2 flex items-center">
                    <input
                      type="radio"
                      name=""
                      v-model="time"
                      value="Received"
                      id=""
                    />
                    <p class="text-xs ml-3 leading-7">Received</p>
                  </div>
                  <div class="mt-2 flex items-center">
                    <input
                      type="radio"
                      v-model="time"
                      value="Paid Out"
                      name=""
                      id=""
                    />
                    <p class="text-xs ml-3 leading-7">Paid Out</p>
                  </div>
                  <div class="mt-2 flex items-center">
                    <input
                      v-model="time"
                      value="failed"
                      type="radio"
                      name=""
                      id=""
                    />
                    <p class="text-xs ml-3 leading-7">Failed</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="grid grid-cols-3 tbs-blue mt-6 border border-b-0  rounded-tr-md rounded-tl-md  "
      >
        <div class="py-6 text-center uppercase text-xs">Category</div>
        <div class="py-6 text-center uppercase text-xs">Quantity</div>
        <div class="py-6 text-center uppercase text-xs">Gross</div>
      </div>
      <div class="  border  rounded-br-md rounded-bl-md  ">
        <div
          v-for="i in 7"
          class="grid grid-cols-3 border-b last:border-b-0 "
          :key="i"
        >
          <div class="py-6 text-center uppercase text-xs">Pos</div>
          <div class="py-6 text-center uppercase text-xs">102</div>
          <div class="py-6 text-center uppercase text-xs">NGN 30,468.00</div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ClickOutside from "vue-click-outside";
export default {
  data() {
    return {
      active: false,
      time: "",
      open: false,
    };
  },
  directives: {
    ClickOutside,
  },
  methods: {
    close() {
      this.active = false;
    },
  },
};
</script>

<style>
.product-shadow {
  box-shadow: 0px 0px 20px rgba(22, 120, 176, 0.12);
  border-radius: 15px;
}
.tbs-blue {
  background: #effaff;
}
</style>
