<template>
  <main>
    <trac-back-button>Dashboard</trac-back-button>
    <div class="mt-2 text-lg font-medium">All Insights</div>
    <div class="flex mt-10 items-center">
      <svg
        class="w-6 h-6"
        viewBox="0 0 29 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.68213 13.041V28.041H10.7134V21.4785V21.4785C10.7134 19.4075 12.2874 17.7285 14.229 17.7285C16.1706 17.7285 17.7446 19.4075 17.7446 21.4785V21.4785V28.041H24.7759V13.041"
          stroke="#003283"
          stroke-width="1.65"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.0459 28.041H27.4131"
          stroke="#003283"
          stroke-width="1.65"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.23099 10.3273H1.23099C0.796712 11.2534 1.14853 12.3797 2.01679 12.843C2.26109 12.9733 2.5305 13.0411 2.80365 13.041H25.6552C26.626 13.0413 27.4133 12.2021 27.4135 11.1666C27.4136 10.8752 27.3501 10.5878 27.2279 10.3273L23.8974 5.54102H4.56146L1.23099 10.3273Z"
          fill="#B4E6FF"
          stroke="#003283"
          stroke-width="1.65"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4.56104 5.54102V3.66602L4.56104 3.66602C4.56104 2.63048 5.34803 1.79102 6.31885 1.79102H22.1392V1.79102C23.11 1.79102 23.897 2.63048 23.897 3.66602C23.897 3.66602 23.897 3.66602 23.897 3.66602V5.54102"
          stroke="#003283"
          stroke-width="1.65"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <div class="ml-4">
        <trac-dropdown
          :choice="true"
          title="Select Store"
          :menuItems="getStore"
          v-model="store"
          class="w-40"
        >
        </trac-dropdown>
      </div>
    </div>
    <div class="grid grid-cols-3 mt-16 shad ">
      <div
        @click="insights = 'salesInsights'"
        :class="{
          'huey  border-b-4 border-primaryBlue text-primaryBlue font-medium ':
            insights == 'salesInsights',
        }"
        class="text-center text-sm pb-3 pt-10 cursor-pointer  text-gray-600 font-light  "
      >
        Sales Insights
      </div>
      <div
        @click="insights = 'inventoryInsights'"
        :class="{
          'huey  border-b-4 border-primaryBlue text-primaryBlue font-medium ':
            insights == 'inventoryInsights',
        }"
        class="text-center text-sm pb-3 pt-10 cursor-pointer  text-gray-600  font-light  "
      >
        Inventory Insights
      </div>
      <div
        @click="insights = 'customerInsights'"
        :class="{
          'huey  border-b-4 border-primaryBlue text-primaryBlue font-medium ':
            insights == 'customerInsights',
        }"
        class="text-center text-sm pb-3 pt-10 cursor-pointer  text-gray-600 font-light  "
      >
        Customer Insights
      </div>
    </div>
    <div class="mt-12">
      <trac-dropdown
        :choice="false"
        title="Select Store"
        :menuItems="select"
        v-model="salesinsightsSelected"
        class=" w-64"
      >
      </trac-dropdown>
      <div class="mt-16">
        <HourlyChart v-if="salesinsightsSelected == 'Hourly Sales'" />
        <DailyChart v-if="salesinsightsSelected == 'Daily Sales'" />
        <ProductSalesTables v-if="salesinsightsSelected == 'Product Sales'" />
        <PaymentMethodTables
          v-if="salesinsightsSelected == 'Payment Methods'"
        />
        <CategorySalesTables v-if="salesinsightsSelected == 'Category Sales'" />
        <DiscountTables v-if="salesinsightsSelected == 'Discount'" />
        <InventoryValueTables
          v-if="salesinsightsSelected == 'Inventory Value'"
        />
        <InventoryDaysTables v-if="salesinsightsSelected == 'Inventory Days'" />
        <LowStockItemsTables
          v-if="salesinsightsSelected == 'Low Stock Items'"
        />
        <AllCustomersTable v-if="salesinsightsSelected == 'All Customers'" />
        <TopCustomerTable v-if="salesinsightsSelected == 'Top Customer'" />
      </div>
    </div>
  </main>
</template>

<script>
import ProductSalesTables from "./ProductSalesTables";
import PaymentMethodTables from "./PaymentMethodTables";
import CategorySalesTables from "./CategorySalesTables";
import DiscountTables from "./DiscountTables";
import InventoryValueTables from "./InventoryValueTables";
import InventoryDaysTables from "./InventoryDaysTables";
import LowStockItemsTables from "./LowStockItemsTables";
import AllCustomersTable from "./AllCustomersTable";
import TopCustomerTable from "./TopCustomerTable";
import DailyChart from "./DailyChart";
import HourlyChart from "./HourlyChart";

export default {
  data() {
    return {
      storeData: [],
      store: "",
      insights: "salesInsights",
      customerInsightsDropdown: [
        {
          name: "All Customers",
        },
        {
          name: "Top Customer",
        },
        {
          name: "Inactive Customer",
        },
        {
          name: "Recent Customer",
        },
        {
          name: "Loyalty Customer",
        },
        {
          name: "Store Credit",
        },
      ],
      inventoryInsightsDropdown: [
        {
          name: "Inventory Value",
        },
        {
          name: "Inventory Days",
        },
        {
          name: "Inventory Category",
        },
        {
          name: "Low Stock Items",
        },
      ],
      salesinsightsDropdown: [
        {
          name: "Daily Sales",
        },
        {
          name: "Product Sales",
        },
        {
          name: "Hourly Sales",
        },
        {
          name: "Payment Methods",
        },
        {
          name: "Category Sales",
        },
        {
          name: "Sub-Category Sales",
        },
        {
          name: "User Sales",
        },
        {
          name: "Employee Sales",
        },
        {
          name: "Store Sales",
        },
        {
          name: "Discount",
        },

        // {
        //   name: "Returns",

        // },
        // {
        //   name: "Void Sales",

        // },
      ],
      salesinsightsSelected: "Product Sales",
    };
  },
  components: {
    ProductSalesTables,
    PaymentMethodTables,
    CategorySalesTables,
    DiscountTables,
    InventoryValueTables,
    InventoryDaysTables,
    LowStockItemsTables,
    AllCustomersTable,
    TopCustomerTable,
    DailyChart,
    HourlyChart,
  },

  computed: {
    getStore() {
      let u = this.storeData.map((x, i) => {
        let k = {};
        k.name = x.name;
        k._id = x._id;
        return k;
      });
      let v = [{ name: "all", _id: "all" }, ...u];
      return v;
    },
    select() {
      switch (this.insights) {
        case "salesInsights":
          this.salesinsightsSelected = "Product Sales";
          return this.salesinsightsDropdown;
          break;
        case "inventoryInsights":
          this.salesinsightsSelected = "Inventory Value";
          return this.inventoryInsightsDropdown;
          break;
        case "customerInsights":
          this.salesinsightsSelected = "All Customers";
          return this.customerInsightsDropdown;
          break;
        default:
          break;
      }
    },
  },
  methods: {
    async fetchAllStores() {
      await this.$store.dispatch("FETCH_ALL_STORES");
      const res = this.$store.getters["GET_ALL_STORES"];
      
      if (res.status) {
        this.storeData = res.data || {};
      }
    },
  },
  async created() {
    let res = await this.fetchAllStores();
  },
};
</script>

<style>
.shad {
  box-shadow: 0px 4px 14px rgba(1, 67, 170, 0.17);
}
.huey {
  background: rgba(37, 59, 149, 0.04);
}
</style>
