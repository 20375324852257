<template>
  <div class="two bg-accentLight rounded-md p-5">
    <div
      class="flex justify-between items-center pb-6 border-b border-gray-200"
    >
      <div>
        <p class="text-primaryGray tiny">
          {{ true ? "Sales value" : "Sales volume" }}
        </p>
        <h2 class="text-sm pl-3 tracking-wide font-medium" v-if="true">5</h2>
        <h2 class="text-sm pl-3 tracking-wide font-medium" v-else>
          4 Sales(s)
        </h2>
      </div>
      <div class="inline-flex items-center">
        <div class="flex space-x-5">
          <div class="flex items-center">
            <trac-checkbox></trac-checkbox>
            <div class="text-sm ml-2">Sales</div>
          </div>
          <div class="flex items-center">
            <trac-checkbox></trac-checkbox>
            <div class="text-sm ml-2">Value</div>
          </div>
          <div class="flex items-center">
            <trac-checkbox></trac-checkbox>
            <div class="text-sm ml-2">Cost</div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-" v-if="[1, 3].length > 0">
      <zingchart
        id="zingchart-vue-18-graph-id0-scale_x-item_title"
        :height="280"
        :data="allchartData || {}"
      ></zingchart>
    </div>
    <div class="w-full h-full all-min" v-else>
      <trac-center-data>
        <p class="text-gray-500 pt-20">No sales made yet.</p>
      </trac-center-data>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    allchartData() {
      return {
        type: "area",
        backgroundColor: "none",
        scaleY: {
          lineColor: "none",
          tick: {
            visible: false,
          },
          guide: {
            visible: false,
          },
          item: {
            visible: false,
          },
        },
        scaleX: {
          label: {
            /* Add a scale title with a label object. */
            text: `Sales Margins from mondy to saturday
            `,
          },
          /* Add your scale labels with a labels array. */
          labels: ["mon", "tue", "wed", "thur", "fri", "sat"],
        },
        series: [
          {
            values: [3, 5, 6, 8, 9, 0],
            "background-color": "#07b2c9 #234398",
            "alpha-area": 0.2,
            "line-color": "#253B95",
            marker: {
              "background-color": "#ffffff" /* hexadecimal or RGB value */,
              size: 4 /* in pixels */,
              "border-color": "#253b95" /* hexadecimal or RBG value */,
              "border-width": 2 /* in pixels */,
            },
          },
        ],
      };
    },
  },
};
</script>

<style>
.light-blue {
  background: #effafe;
}
</style>
