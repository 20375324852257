var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('trac-back-button',[_vm._v("Dashboard")]),_c('div',{staticClass:"mt-2 text-lg font-medium"},[_vm._v("All Insights")]),_c('div',{staticClass:"flex mt-10 items-center"},[_c('svg',{staticClass:"w-6 h-6",attrs:{"viewBox":"0 0 29 29","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M3.68213 13.041V28.041H10.7134V21.4785V21.4785C10.7134 19.4075 12.2874 17.7285 14.229 17.7285C16.1706 17.7285 17.7446 19.4075 17.7446 21.4785V21.4785V28.041H24.7759V13.041","stroke":"#003283","stroke-width":"1.65","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M1.0459 28.041H27.4131","stroke":"#003283","stroke-width":"1.65","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M1.23099 10.3273H1.23099C0.796712 11.2534 1.14853 12.3797 2.01679 12.843C2.26109 12.9733 2.5305 13.0411 2.80365 13.041H25.6552C26.626 13.0413 27.4133 12.2021 27.4135 11.1666C27.4136 10.8752 27.3501 10.5878 27.2279 10.3273L23.8974 5.54102H4.56146L1.23099 10.3273Z","fill":"#B4E6FF","stroke":"#003283","stroke-width":"1.65","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M4.56104 5.54102V3.66602L4.56104 3.66602C4.56104 2.63048 5.34803 1.79102 6.31885 1.79102H22.1392V1.79102C23.11 1.79102 23.897 2.63048 23.897 3.66602C23.897 3.66602 23.897 3.66602 23.897 3.66602V5.54102","stroke":"#003283","stroke-width":"1.65","stroke-linecap":"round","stroke-linejoin":"round"}})]),_c('div',{staticClass:"ml-4"},[_c('trac-dropdown',{staticClass:"w-40",attrs:{"choice":true,"title":"Select Store","menuItems":_vm.getStore},model:{value:(_vm.store),callback:function ($$v) {_vm.store=$$v},expression:"store"}})],1)]),_c('div',{staticClass:"grid grid-cols-3 mt-16 shad "},[_c('div',{staticClass:"text-center text-sm pb-3 pt-10 cursor-pointer  text-gray-600 font-light  ",class:{
        'huey  border-b-4 border-primaryBlue text-primaryBlue font-medium ':
          _vm.insights == 'salesInsights',
      },on:{"click":function($event){_vm.insights = 'salesInsights'}}},[_vm._v(" Sales Insights ")]),_c('div',{staticClass:"text-center text-sm pb-3 pt-10 cursor-pointer  text-gray-600  font-light  ",class:{
        'huey  border-b-4 border-primaryBlue text-primaryBlue font-medium ':
          _vm.insights == 'inventoryInsights',
      },on:{"click":function($event){_vm.insights = 'inventoryInsights'}}},[_vm._v(" Inventory Insights ")]),_c('div',{staticClass:"text-center text-sm pb-3 pt-10 cursor-pointer  text-gray-600 font-light  ",class:{
        'huey  border-b-4 border-primaryBlue text-primaryBlue font-medium ':
          _vm.insights == 'customerInsights',
      },on:{"click":function($event){_vm.insights = 'customerInsights'}}},[_vm._v(" Customer Insights ")])]),_c('div',{staticClass:"mt-12"},[_c('trac-dropdown',{staticClass:" w-64",attrs:{"choice":false,"title":"Select Store","menuItems":_vm.select},model:{value:(_vm.salesinsightsSelected),callback:function ($$v) {_vm.salesinsightsSelected=$$v},expression:"salesinsightsSelected"}}),_c('div',{staticClass:"mt-16"},[(_vm.salesinsightsSelected == 'Hourly Sales')?_c('HourlyChart'):_vm._e(),(_vm.salesinsightsSelected == 'Daily Sales')?_c('DailyChart'):_vm._e(),(_vm.salesinsightsSelected == 'Product Sales')?_c('ProductSalesTables'):_vm._e(),(_vm.salesinsightsSelected == 'Payment Methods')?_c('PaymentMethodTables'):_vm._e(),(_vm.salesinsightsSelected == 'Category Sales')?_c('CategorySalesTables'):_vm._e(),(_vm.salesinsightsSelected == 'Discount')?_c('DiscountTables'):_vm._e(),(_vm.salesinsightsSelected == 'Inventory Value')?_c('InventoryValueTables'):_vm._e(),(_vm.salesinsightsSelected == 'Inventory Days')?_c('InventoryDaysTables'):_vm._e(),(_vm.salesinsightsSelected == 'Low Stock Items')?_c('LowStockItemsTables'):_vm._e(),(_vm.salesinsightsSelected == 'All Customers')?_c('AllCustomersTable'):_vm._e(),(_vm.salesinsightsSelected == 'Top Customer')?_c('TopCustomerTable'):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }